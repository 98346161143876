var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"listEventcalendar"},[_c('div',{staticClass:"B-carddate"},[_c('div',{staticClass:"T-size-20"},[_vm._v(_vm._s(_vm.Datadate))])]),(_vm.Service == 'bookingselect')?_c('div',_vm._l((_vm.itemsListFlight),function(item){return _c('div',{key:item.b_id,staticClass:"box-S4"},[_c('div',{staticClass:"B-carddetail",staticStyle:{"border-width":"2px","border-style":"solid"},style:([
          _vm.itemsListAirport.find(function (x) { return x.ap_id === item.ap_id; }).ap_name ==
            'VTSH'
            ? { 'border-color': 'black' }
            : { 'border-color': '#f7f7f7;' } ])},[_c('div',{staticClass:"b-dateTime"},[_c('div',{staticClass:"date T-size-20 SemiBold"},[_c('span',{staticClass:"I-calendar"}),_vm._v(" "+_vm._s(_vm._f("yyyyMMdd")(item.b_departure_date))+" ")]),_c('div',{staticClass:"time T-size-20 SemiBold"},[_c('span',{staticClass:"I-time"}),_vm._v(" "+_vm._s(_vm._f("HHmm")(item.b_departure_date))+" ")])]),_c('div',{staticClass:"b-textData"},[_c('div',{staticClass:"t-owner"},[_c('span',[_vm._v("Departure From : "+_vm._s(_vm.itemsListAirport.find(function (x) { return x.ap_id === item.ap_id; }).ap_name))]),_c('br'),_c('span',[_vm._v("Route : "+_vm._s(item.legs))]),_c('br'),_c('span',[_vm._v("Customer : "+_vm._s(item.customer.o_name))]),_c('br'),_c('span',[_vm._v("Flight No : "+_vm._s(item.f_no))]),_c('br'),_c('span',[_vm._v("Passenger Out : "+_vm._s(item.bookingpassenger.filter(function (x) { return x.pt_id == 1 && x.retire == '0'; }).length))]),_c('br'),_c('span',[_vm._v("Passenger In : "+_vm._s(item.bookingpassenger.filter(function (x) { return x.pt_id == 2 && x.retire == '0'; }).length))])])]),_c('div',{staticClass:"line red"}),_c('div',{staticClass:"b-tagBtn"},[_c('div',{staticClass:"b-tag"},[_c('div',{staticClass:"tag T-size-12 tagRefer"},[_vm._v(" Ref ID : "+_vm._s(item.ref_id)+" ")]),_c('div',{staticClass:"tag T-size-12 tagStatus"},[_vm._v(" Status : "+_vm._s(item.bookingstatus.bs_name)+" ")])]),_c('v-btn',{staticClass:"theme-btn-even",attrs:{"to":{
              name: 'BookingDetail',
              params: { mode: 'Edit', id: item.b_id },
            }}},[_c('span',[_vm._v("VIEW BOOKING")]),_c('span',{staticClass:"I-arrrowR"})])],1)])])}),0):_vm._e(),(_vm.Service == 'flightselect')?_c('div',_vm._l((_vm.itemsListFlight),function(item){return _c('div',{key:item.f_id,staticClass:"box-S4"},[_c('div',{staticClass:"B-carddetail",staticStyle:{"border-width":"2px","border-style":"solid"},style:([
          _vm.itemsListAirport.find(function (x) { return x.ap_id === item.ap_departairport_id; })
            .ap_name == 'VTSH'
            ? { 'border-color': 'black' }
            : { 'border-color': '#f7f7f7;' } ])},[_c('div',{staticClass:"b-dateTime"},[_c('div',{staticClass:"date T-size-20 SemiBold"},[_c('span',{staticClass:"I-calendar"}),_vm._v(" "+_vm._s(_vm._f("yyyyMMdd")(item.ap_depart_date))+" "),(item.f_accept_pilot)?_c('span',{staticClass:"I-pilot"}):_vm._e()]),_c('div',{staticClass:"time T-size-20 SemiBold"},[_c('span',{staticClass:"I-time"}),_vm._v(" "+_vm._s(_vm._f("HHmm")(item.ap_depart_date))+" ")])]),_c('div',{staticClass:"b-textData"},[_c('div',{staticClass:"t-owner"},[_c('span',[_vm._v("Departure From : "+_vm._s(_vm.itemsListAirport.find( function (x) { return x.ap_id === item.ap_departairport_id; } ).ap_name))]),_c('br'),_c('span',[_vm._v("Customer : "+_vm._s(item.customer.o_name))]),_c('br'),(item.f_flight_no == '')?_c('span',[_vm._v("Flight No : -")]):_c('span',[_vm._v("Flight No : "+_vm._s(item.f_flight_no))]),_c('br'),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.userdata.r_id != 16),expression:"userdata.r_id != 16"}]},[(item.f_pilot_name == '' || item.f_pilot_name == null)?_c('span',[_vm._v("Pilot : -")]):_c('span',[_vm._v("Pilot : "+_vm._s(item.f_pilot_name))]),_c('br'),(
                  item.f_copilot_name == '' || item.f_copilot_name == null
                )?_c('span',[_vm._v("Co Pilot : -")]):_c('span',[_vm._v("Co Pilot : "+_vm._s(item.f_copilot_name))])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.userdata.r_id != 16),expression:"userdata.r_id != 16"}],staticClass:"t-aircraft"},[_vm._v(" Aircraft : "),(item.aircrafts == null)?_c('span',[_vm._v("-")]):_c('span',[_vm._v(_vm._s(item.aircrafts.ac_name))])])]),_c('div',{staticClass:"line red"}),_c('div',{staticClass:"b-tagBtn"},[_c('div',{staticClass:"b-tag"},[_c('div',{staticClass:"tag T-size-12 tagRefer"},[_vm._v(" Ref ID : "+_vm._s(item.f_flight_no)+" ")]),_c('div',{staticClass:"tag T-size-12 tagStatusActive"},[_vm._v(" Status : "+_vm._s(item.flightstatus.fs_name.toUpperCase())+" ")])]),_c('div',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.userdata.r_id == 16 ||
                (_vm.userdata.r_id == 2 && _vm.userdata.o_id == 4)
              ),expression:"\n                userdata.r_id == 16 ||\n                (userdata.r_id == 2 && userdata.o_id == 4)\n              "}],staticClass:"theme-btn-even",staticStyle:{"margin-right":"10px"},attrs:{"to":{
  name: 'MubadalaDetail',
  params: { mode: 'Edit', id: item.f_id },
}}},[_c('span',[_vm._v("E-FORM MUBADALA")])]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.userdata.r_id != 16),expression:"userdata.r_id != 16"}],staticClass:"theme-btn-even",attrs:{"to":{
                name: 'FlightDetail',
                params: { mode: 'Edit', id: item.f_id },
              }}},[_c('span',[_vm._v("VIEW FLIGHT")]),_c('span',{staticClass:"I-arrrowR"})])],1)])])])}),0):_vm._e(),(_vm.Service == 'transportselect')?_c('div',_vm._l((_vm.itemsListFlight),function(item){return _c('div',{key:item.ts_id,staticClass:"box-S4"},[_c('div',{staticClass:"B-carddetail"},[_c('div',{staticClass:"b-dateTime"},[_c('div',{staticClass:"date T-size-20 SemiBold"},[_c('span',{staticClass:"I-calendar"}),_vm._v(" "+_vm._s(_vm._f("yyyyMMdd")(item.ts_date))+" ")]),_c('div',{staticClass:"time T-size-20 SemiBold"},[_c('span',{staticClass:"I-time"}),_vm._v(" "+_vm._s(_vm._f("HHmm")(item.ts_date))+" ")])]),_c('div',{staticClass:"line red"}),_c('div',{staticClass:"b-tagBtn"},[_c('div',{staticClass:"b-tag"},[_c('div',{staticClass:"t-owner"},[_c('span',[_vm._v("Customer : "+_vm._s(item.customer[0].o_name))])]),_c('div',{staticClass:"tag T-size-12 tagRefer"},[_vm._v(" Ref ID : "+_vm._s(item.ref_id)+" ")])]),_c('v-btn',{staticClass:"theme-btn-even",attrs:{"to":{
              name: 'TransportDetail',
              params: { mode: 'Edit', id: item.ts_id },
            }}},[_c('span',[_vm._v("VIEW TRANSPORT")]),_c('span',{staticClass:"I-arrrowR"})])],1)])])}),0):_vm._e(),(_vm.Service == 'flightlogselect')?_c('div',_vm._l((_vm.itemsListFlight),function(item){return _c('div',{key:item.f_id,staticClass:"box-S4"},[_c('div',{staticClass:"B-carddetail",staticStyle:{"border-width":"2px","border-style":"solid"},style:([
          _vm.itemsListAirport.find(function (x) { return x.ap_id === item.ap_departairport_id; })
            .ap_name == 'VTSH'
            ? { 'border-color': 'black' }
            : { 'border-color': '#f7f7f7;' } ])},[_c('div',{staticClass:"b-dateTime"},[_c('div',{staticClass:"date T-size-20 SemiBold"},[_c('span',{staticClass:"I-calendar"}),_vm._v(" "+_vm._s(_vm._f("yyyyMMdd")(item.ap_depart_date))+" ")]),_c('div',{staticClass:"time T-size-20 SemiBold"},[_c('span',{staticClass:"I-time"}),_vm._v(" "+_vm._s(_vm._f("HHmm")(item.ap_depart_date))+" ")])]),_c('div',{staticClass:"b-textData"},[_c('div',{staticClass:"t-owner"},[_c('span',[_vm._v("Departure From : "+_vm._s(_vm.itemsListAirport.find( function (x) { return x.ap_id === item.ap_departairport_id; } ).ap_name))]),_c('br'),_c('span',[_vm._v("Customer : "+_vm._s(item.customer.o_name))]),_c('br'),(item.f_flight_no == '')?_c('span',[_vm._v("Flight No : -")]):_c('span',[_vm._v("Flight No : "+_vm._s(item.f_flight_no))])]),_c('div',{staticClass:"t-aircraft"},[_vm._v(" Aircraft : "),(item.aircrafts == null)?_c('span',[_vm._v("-")]):_c('span',[_vm._v(_vm._s(item.aircrafts.ac_name))])])]),_c('div',{staticClass:"line red"}),_c('div',{staticClass:"b-tagBtn"},[_c('div',{staticClass:"b-tag"},[(item.ref_id != null)?_c('div',{staticClass:"tag T-size-12 tagRefer"},[_vm._v(" Ref ID : "+_vm._s(item.ref_id)+" ")]):_vm._e(),_c('div',{staticClass:"tag T-size-12 tagStatusTakeoff"},[_vm._v(" Status : "+_vm._s(item.flightstatus.fs_name)+" ")])]),_c('v-btn',{staticClass:"theme-btn-even",attrs:{"to":{
              name: 'FlightLogDetail',
              params: { mode: 'Edit', id: item.f_id },
            }}},[_c('span',[_vm._v("VIEW FLIGHT REPORT")]),_c('span',{staticClass:"I-arrrowR"})])],1)])])}),0):_vm._e(),(_vm.Service == 'fuelselect')?_c('div',_vm._l((_vm.itemsListFlight),function(item){return _c('div',{key:item.f_id,staticClass:"box-S4"},[_c('div',{staticClass:"B-carddetail",staticStyle:{"border-width":"2px","border-style":"solid"},style:([
          _vm.itemsListAirport.find(function (x) { return x.ap_id === item.ap_departairport_id; })
            .ap_name == 'VTSH'
            ? { 'border-color': 'black' }
            : { 'border-color': '#f7f7f7;' } ])},[_c('div',{staticClass:"b-dateTime"},[_c('div',{staticClass:"date T-size-20 SemiBold"},[_c('span',{staticClass:"I-calendar"}),_vm._v(" "+_vm._s(_vm._f("yyyyMMdd")(item.ap_depart_date))+" ")]),_c('div',{staticClass:"time T-size-20 SemiBold"},[_c('span',{staticClass:"I-time"}),_vm._v(" "+_vm._s(_vm._f("HHmm")(item.ap_depart_date))+" ")])]),_c('div',{staticClass:"b-textData"},[_c('div',{staticClass:"t-owner"},[_c('span',[_vm._v("Departure From : "+_vm._s(_vm.itemsListAirport.find( function (x) { return x.ap_id === item.ap_departairport_id; } ).ap_name))]),_c('br'),_c('span',[_vm._v(_vm._s(item.customer.o_name)+" : "+_vm._s(item.f_flight_no))])]),_c('div',{staticClass:"t-aircraft"},[_vm._v(" Aircraft : "),(item.aircrafts == null)?_c('span',[_vm._v("-")]):_c('span',[_vm._v(_vm._s(item.aircrafts.ac_name))])])]),_c('div',{staticClass:"line red"}),_c('div',{staticClass:"b-tagBtn"},[_c('div',{staticClass:"b-tag"},[_c('div',{staticClass:"tag T-size-12 tagRefer"},[_vm._v(" Ref ID : "+_vm._s(item.f_flight_no)+" ")])]),_c('v-btn',{staticClass:"theme-btn-even",attrs:{"to":{
              name: 'ManageFuel',
              params: { idflight: item.f_id },
            }}},[_c('span',[_vm._v("Fueling")]),_c('span',{staticClass:"I-arrrowR"})])],1)])])}),0):_vm._e(),(_vm.itemsListFlight.length == 0)?_c('div',[_vm._v("No data")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }