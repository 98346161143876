var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Content-Page",attrs:{"id":"flightList"}},[_c('div',{staticClass:"wrap-Main",attrs:{"id":"namePage"}},[_c('div',{staticClass:"box-S4 flex-between-center"},[_c('div',{staticClass:"N-Page T-size-36"},[_vm._v("Flight Schedule")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.userdata.r_id == 4 || _vm.userdata.r_id == 21),expression:"userdata.r_id == 4 || userdata.r_id == 21"}],staticClass:"theme-btn-even",on:{"click":function($event){return _vm.createFlight()}}},[_c('span',{staticClass:"I-create"}),_c('span',[_vm._v("Create Flight")])])],1)]),_c('div',{staticClass:"wrap-Main",attrs:{"id":"calendarDATA"}},[_c('div',{staticClass:"box-S2"},[_c('CompListFlight',{ref:"CompListFlight",attrs:{"DataDateFromCalendar":_vm.datadatefromcalender,"Service":'flightselect'}})],1),_c('div',{staticClass:"box-S2"},[_c('calendar',{attrs:{"Service":'flightselect'},on:{"DataCalender":_vm.BindingDataFromCalendar}})],1)]),_c('div',{staticClass:"wrap-Main",attrs:{"id":"partBack"}},[_c('div',{staticClass:"line"}),_c('div',{staticClass:"box-S4"},[_c('v-btn',{staticClass:"ColorTheme-S white--text theme-btn",attrs:{"text":""},on:{"click":function($event){return _vm.Back()}}},[_c('span',{staticClass:"I-back"}),_c('span',[_vm._v("Back")])])],1)]),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.dialogCreateFlight),callback:function ($$v) {_vm.dialogCreateFlight=$$v},expression:"dialogCreateFlight"}},[_c('v-card',{staticClass:"TF type2",attrs:{"id":"dialog"}},[_c('div',{staticClass:"b-content"},[_c('div',{staticClass:"box-S4 flex-between-center"},[_c('div',{staticClass:"N-Page T-size-24 TF"},[_vm._v("Create Flight")])]),_c('div',{staticClass:"B-carddetail B-formMain"},[_c('div',{staticClass:"b-row"},[_c('div',{staticClass:"box-S4"},[_c('v-text-field',{attrs:{"label":"Flight No.","readonly":_vm.readonly_eng ||
                  _vm.readonly_pilot ||
                  _vm.readonly_shore ||
                  _vm.readonly_finance},model:{value:(_vm.flightno),callback:function ($$v) {_vm.flightno=$$v},expression:"flightno"}})],1)]),_c('div',{staticClass:"b-row"},[_c('div',{staticClass:"box-S4"},[_c('v-select',{staticClass:"hideMessage",attrs:{"items":_vm.itemsListCustomer,"item-text":"o_name","item-value":"o_id","label":"Customer","readonly":_vm.readonly_eng ||
                  _vm.readonly_pilot ||
                  _vm.readonly_shore ||
                  _vm.readonly_finance,"return-object":""},model:{value:(_vm.customer),callback:function ($$v) {_vm.customer=$$v},expression:"customer"}})],1)]),_c('div',{staticClass:"b-row"},[_c('div',{staticClass:"box-S4"},[_c('v-select',{staticClass:"hideMessage",attrs:{"items":_vm.itemsListFlightType,"item-text":"ft_name","item-value":"ft_id","label":"Type Of Flight","readonly":_vm.readonly_eng ||
                  _vm.readonly_pilot ||
                  _vm.readonly_shore ||
                  _vm.readonly_finance,"return-object":""},model:{value:(_vm.flighttype),callback:function ($$v) {_vm.flighttype=$$v},expression:"flighttype"}})],1)]),_c('div',{staticClass:"b-row"},[_c('div',{staticClass:"box-S4"},[_c('v-select',{staticClass:"hideMessage",attrs:{"items":_vm.itemsListTerminal,"item-text":"tm_name","item-value":"tm_id","label":"Terminal","readonly":_vm.readonly_eng ||
                  _vm.readonly_pilot ||
                  _vm.readonly_shore ||
                  _vm.readonly_finance,"return-object":""},model:{value:(_vm.terminal),callback:function ($$v) {_vm.terminal=$$v},expression:"terminal"}})],1)]),_c('div',{staticClass:"b-row"},[_c('div',{staticClass:"box-S4"},[_c('v-select',{staticClass:"hideMessage",attrs:{"items":_vm.itemsListCheckInVideo,"item-text":"v_name","item-value":"v_id","label":"Brief Check in Video","readonly":_vm.readonly_eng ||
                  _vm.readonly_pilot ||
                  _vm.readonly_shore ||
                  _vm.readonly_finance,"return-object":""},model:{value:(_vm.videoCheckin),callback:function ($$v) {_vm.videoCheckin=$$v},expression:"videoCheckin"}})],1)]),_c('div',{staticClass:"b-row"},[_c('div',{staticClass:"box-S2"},[_c('v-menu',{attrs:{"disabled":_vm.readonly_eng ||
                  _vm.readonly_pilot ||
                  _vm.readonly_shore ||
                  _vm.readonly_finance,"close-on-content-click":false,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"hideMessage input-date",attrs:{"label":"Departure Date","readonly":"","append-icon":"mdi-calendar-range"},model:{value:(_vm.depart_date),callback:function ($$v) {_vm.depart_date=$$v},expression:"depart_date"}},on))]}}]),model:{value:(_vm.menuselect_depart_date),callback:function ($$v) {_vm.menuselect_depart_date=$$v},expression:"menuselect_depart_date"}},[_c('v-date-picker',{attrs:{"readonly":_vm.readonly_eng ||
                    _vm.readonly_pilot ||
                    _vm.readonly_shore ||
                    _vm.readonly_finance},on:{"input":function($event){_vm.menuselect_depart_date = false}},model:{value:(_vm.depart_date),callback:function ($$v) {_vm.depart_date=$$v},expression:"depart_date"}})],1)],1),_c('div',{staticClass:"box-S2"},[_c('v-text-field',{attrs:{"label":"Departure Time","readonly":_vm.readonly_eng ||
                  _vm.readonly_pilot ||
                  _vm.readonly_shore ||
                  _vm.readonly_finance,"type":"time"},model:{value:(_vm.depart_time),callback:function ($$v) {_vm.depart_time=$$v},expression:"depart_time"}})],1)]),_c('div',{staticClass:"b-row"},[_c('div',{staticClass:"box-S4"},[_c('v-combobox',{staticClass:"hideMessage",attrs:{"items":_vm.itemsListDeparture,"item-text":"ap_name","item-value":"ap_id","label":"Departure From","return-object":"","readonly":_vm.readonly_eng ||
                  _vm.readonly_pilot ||
                  _vm.readonly_shore ||
                  _vm.readonly_finance},on:{"change":_vm.departureFromChange},model:{value:(_vm.selectDeparture),callback:function ($$v) {_vm.selectDeparture=$$v},expression:"selectDeparture"}})],1)]),_c('div',{staticClass:"box-btn"},[_c('v-btn',{staticClass:"theme-btn-even btn-cancel Bsize100",attrs:{"text":""},on:{"click":function($event){_vm.dialogCreateFlight = false}}},[_c('span',{staticClass:"T-size-18"},[_vm._v("cancel")])]),_c('v-btn',{staticClass:"theme-btn-even btn-save Bsize100",attrs:{"disabled":_vm.buttonSave,"text":""},on:{"click":function($event){return _vm.GotoCreateFlight()}}},[_c('span',{staticClass:"T-size-18"},[_vm._v("save")])])],1)])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }