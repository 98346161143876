<template>
  <div id="boxCalender" class="B-calendar">
    <div class="b-selectData">
      <div class="box-S2">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="0"
          :nudge-top="0"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              class="hideMessage input-date"
              append-icon="mdi-calendar-range"
              v-model="picker"
              label="Select Date"
              readonly
              dense
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="picker"
            type="month"
            @change="pickerChange"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>
      </div>
      <div class="box-S2">
        <v-select
          class="hideMessage"
          :items="itemsListCustomer"
          v-model="selectcustomer"
          item-text="o_name"
          item-value="o_id"
          label="Customer"
          return-object
          @change="customerChange"
        ></v-select>
      </div>
      <div class="box-S2">
        <v-select
          class="hideMessage"
          :items="itemsListDeparture"
          v-model="selectDeparture"
          item-text="ap_name"
          item-value="ap_id"
          label="Departure From"
          return-object
          @change="departureFromChange"
        ></v-select>
      </div>
    </div>
    <div class="box-S4">
      <v-calendar
        ref="calendar"
        :now="today"
        v-model="startCalendar"
        type="month"
        :end="endCalendar"
        @click:date="CalendarClick"
      >
        <template v-slot:day="{ date }">
          <template v-for="event in eventsMap[date]">
            <v-menu :key="event.title" v-model="event.open" full-width offset-x>
              <template v-slot:activator="{ on }">
                <div
                  v-if="!event.time"
                  v-ripple
                  class="my-event"
                  v-on="on"
                  v-html="event.title"
                ></div>
              </template>
            </v-menu>
          </template>
        </template>
      </v-calendar>
    </div>
  </div>
</template>


<script>
import feathersClientUOA from "../../plugins/feathers-client-uoa";
import {
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
  lastDayOfYear,
  format,
  getDate,
} from "date-fns";

export default {
  data: () => ({
    //Add
    today: new Date().toISOString().substr(0, 10),
    userdata: "",

    items: [],
    total: 0,
    loading: false,
    dialog: false,
    formModel: {},
    type: "month",
    startCalendar: null,
    endCalendar: null,
    picker: {},
    datenow: new Date(),
    dateSelect: new Date().toISOString().substr(0, 7),
    menu: false,
    events: [],
    CaledarData: [],
    // itemsListCompany: [],
    // selectcompany: ""
    //Edit
    itemsListCustomer: [],
    selectcustomer: "",
    itemsListDeparture: [],
    selectDeparture: {},
    myDate: "",
  }),
  props: ["Service"],
  computed: {
    eventsMap() {
      const map = {};
      this.events.forEach((e) => (map[e.date] = map[e.date] || []).push(e));
      return map;
    },
  },
  async mounted() {
    //Add
    this.userdata = await this.$CurrentUser.GetCurrentUser();

    //init here
    this.picker = this.dateSelect;

    //ปิด
    //await this.getDataStart();

    // this.SelectListCompany();
    //Edit
    await this.SelectListCustomer();
    await this.renderListDeparture();

    this.customerChange();
    //ปิด
    //this.$emit("DataCalender",{"date" : this.today, "o_id": this.selectcustomer.o_id });
  },

  methods: {
    open(event) {
      alert(event.title);
    },

    // async getDataStart() {
    //   const q = {};
    //   var x = this.dateSelect.substr(0, 4);
    //   var y = this.dateSelect.substr(5, 2);
    //   //   var owner = this.$store.state.auth.user.Owner;
    //   // if(owner == "UOA"){
    //   //     owner = null
    //   // }else if (owner != "UOA"){

    //   //    owner = this.$store.state.auth.user.Owner;
    //   // }
    //   q.month = y;
    //   q.year = x;

    //   //Add
    //   if(this.userdata.r_id == 4 || this.userdata.r_id == 5 || this.userdata.r_id == 9 || this.userdata.r_id == 12){
    //     q.o_id = 0;
    //   }else{
    //     q.o_id = this.userdata.o_id;
    //   }
    //   //q.owner = owner;
    //   try {
    //     var res = await feathersClientUOA
    //         .service(this.Service)
    //         .find({ query: q });
    //     this.items = res[0].data[0];
    //     this.CaledarData = res[0].caledarData;
    //   } catch (error) {
    //     console.log(error);
    //   }
    //   this.events = this.CaledarData;
    //   // alert(JSON.stringify(this.events))
    // },

    // async SelectListCompany() {
    //   try {
    //     var res = await feathersClientUOA.service("company").find({});
    //     res.data.forEach(element => {
    //       this.itemsListCompany.push(element.c_name);
    //     });
    //   } catch (error) {
    //     console.log(error);
    //     alert("ไม่สามารถขอข้อมูลจาก server ได้");
    //   }
    // },
    //Edit
    async SelectListCustomer() {
      try {
        const q = {};
        if (this.userdata.r_id == 2) {
          q.o_id = this.userdata.o_id;
        }

        if (this.userdata.r_id == 16) {
          q.o_id = this.userdata.o_id;
        }
        var res = await feathersClientUOA
          .service("customer")
          .find({ query: q });
        res.data.forEach((element) => {
          if (
            this.userdata.r_id == 4 ||
            this.userdata.r_id == 21 ||
            this.userdata.r_id == 5 ||
            this.userdata.r_id == 9 ||
            this.userdata.r_id == 12 ||
            this.userdata.r_id == 13 ||
            this.userdata.r_id == 14 ||
            this.userdata.r_id == 15 ||
            this.userdata.r_id == 8
          ) {
            this.itemsListCustomer.push({ o_id: 0, o_name: "All" });
          }
          this.itemsListCustomer.push({
            o_id: element.o_id,
            o_name: element.o_name,
          });
          this.selectcustomer = this.itemsListCustomer[0];
        });
      } catch (error) {
        console.log(
          "SelectListCustomer ไม่สามารถขอข้อมูลจาก server ได้ /nError : " +
            error
        );
      }
    },

    async getData() {
      this.CaledarData = [];
      const q = {};
      //Add
      let date = this.picker;
      let o_id = this.selectcustomer.o_id;
      let ap_id = this.selectDeparture.ap_id;
      //   var owner = this.$store.state.auth.user.Owner;
      // if(owner == "UOA"){
      //     owner = null
      // }else if (owner != "UOA"){

      //    owner = this.$store.state.auth.user.Owner;
      // }

      var x = date.substr(0, 4);
      var y = date.substr(5, 2);
      q.month = y;
      q.year = x;
      //Add
      q.o_id = o_id;
      q.ap_id = ap_id;

      // q.owner = owner;
      //Render Calendar

      var Fdate = new Date(x, y - 1, 1, 0, 0, 0, 0);
      // alert(Fdate);
      var x1 = startOfMonth(Fdate);
      var y1 = endOfMonth(Fdate);

      this.startCalendar = format(x1, "yyyy-MM-dd");
      this.endCalendar = format(y1, "yyyy-MM-dd");

      try {
        //this.Service => bookingselect
        var res = await feathersClientUOA
          .service(this.Service)
          .find({ query: q });
        this.items = res[0].data[0];
        this.CaledarData = res[0].caledarData;
      } catch (error) {
        console.log(error);
      }
      this.events = this.CaledarData;
    },

    CalendarClick(date) {
      //console.log("CalendarClick");
      this.today = date.date;
      this.$emit("DataCalender", {
        date: date.date,
        o_id: this.selectcustomer.o_id,
        ap_id: this.selectDeparture.ap_id,
      });
    },

    pickerChange() {
      //console.log("pickerChange");
      this.today = this.picker + "-01";
      this.$emit("DataCalender", {
        date: this.picker + "-01",
        o_id: this.selectcustomer.o_id,
        ap_id: this.selectDeparture.ap_id,
      });
      this.getData();
    },

    customerChange() {
      //console.log("customerChange");
      this.today = this.today;
      this.$emit("DataCalender", {
        date: this.today,
        o_id: this.selectcustomer.o_id,
        ap_id: this.selectDeparture.ap_id,
      });
      this.getData();
    },

    departureFromChange() {
      this.$LocalStorageService.setDepartureChange(
        this.selectDeparture.ap_id,
        this.selectDeparture.ap_name
      );
      this.$emit("DataCalender", {
        date: this.today,
        o_id: this.selectcustomer.o_id,
        ap_id: this.selectDeparture.ap_id,
      });
      this.getData();
    },

    async renderListDeparture() {
      try {
        var data = await feathersClientUOA.service("mastercommand").find({});
        var airports = data[0].airport.filter((x) => x.ap_checkin == true);
        airports.forEach((element) => {
          this.itemsListDeparture.push(element);
        });
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
      this.selectDepartureFrom();
    },

    async selectDepartureFrom() {
      try {
        let departureChange =
          await this.$LocalStorageService.getDepartureChange();
        if (departureChange == null) {
          this.selectDeparture = {
            ap_id: this.itemsListDeparture[0].ap_id,
            ap_name: this.itemsListDeparture[0].ap_name,
          };
        } else {
          let obj = JSON.parse(departureChange);
          this.selectDeparture = { ap_id: obj.ap_id, ap_name: obj.ap_name };
        }
      } catch (error) {
        console.log(
          "SelectListCustomer ไม่สามารถขอข้อมูลจาก server ได้ /nError : " +
            error
        );
      }
    },
  },
};
</script> 
<style  scoped>
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 20px;
  background-color: #ff0001;
  color: #ffffff;
  width: 100%;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  text-align: center;
  font-weight: 500;
  margin-top: 3px;
}
</style>